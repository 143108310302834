import  {Component, Fragment} from 'react';
import classes from './Archives.module.css';
import HealthCardSection from "../chatBuilder/HealthCardSection/HealthCardSection";
import {database} from "../../firebase";
import UserWarning from "../chatBuilder/UserWarning/UserWarning";

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function findDate(scheduled_date) {
    var event = new Date(scheduled_date);
    let date = JSON.stringify(event);
    date = date.slice(1, 11);
    return date;
}

export default class UserDetailedScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showlist: false,
            show_soap_info: true,
            show_general_info: true,
            show_health_info: true,
            showSelectedMessages: null,
            chatType: "message"
        }
    }

    editChatDetail = () => {
        this.props.editChatDetailClick();
    }

    showGeneralInfo = () => {
        this.setState((state) => ({show_general_info: !state.show_general_info}));
    }

    showSOAP = () => {
        this.setState((state) => ({show_soap_info: !state.show_soap_info}));
    }

    showHealthInfo = () => {
        this.setState((state) => ({show_health_info: !state.show_health_info}));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.sessionID !== nextProps.sessionID && nextProps.sessionID){
            database.ref(`/callLogs/${nextProps.sessionID}/callType`).once("value", chatTypeSnap => {
                let chatType = chatTypeSnap.val() || "message";
                this.setState({chatType})
            })
        }
    }

    render() {
        var name = this.props.patientName !== undefined ? capitalize(this.props.patientName) : 'Patient';
        var address = this.props.city !== undefined || this.props.country !== undefined ? this.props.city + ',' + this.props.country : 'Address not available';
        if (Boolean(this.props.complete_address)) address = this.props.complete_address;
        const {scheduled_date} = this.props.patientFollowUpData;
        let healthInfoFound = Boolean(
            (this.props.currentSleep !== "NA" && Boolean(this.props.currentSleep)) ||
            (this.props.averageSleep !== "NA" && Boolean(this.props.averageSleep)) ||
            (this.props.heartRate !== "NA" && Boolean(this.props.heartRate)) ||
            (this.props.restingHeartRate !== "NA" && Boolean(this.props.restingHeartRate)) ||
            ((this.props.heightFeet !== "NA" && Boolean(this.props.heightFeet)) || (this.props.heightCm !== "NA" && Boolean(this.props.heightCm))) ||
            ((this.props.weightLbs !== "NA" && Boolean(this.props.weightLbs)) || (this.props.weightKg !== "NA" && Boolean(this.props.weightKg))) ||
            (this.props.periodStartDate !== "NA" && Boolean(this.props.periodStartDate)) ||
            (this.props.flowType !== "NA" && Boolean(this.props.flowType)) ||
            (this.props.stepCount !== "NA" && Boolean(this.props.stepCount)) ||
            (this.props.runningWalking !== "NA" && Boolean(this.props.runningWalking))
        );

        console.log("archiveuserdetail this.props.archiveOwnerProfile", this.props.archiveOwnerProfile)
        console.log("archiveuserdetail this.props.archive_healthInsuranceCardBack", this.props.archive_healthInsuranceCardBack)
        console.log("archiveuserdetail this.props.archive_healthInsuranceCardFront", this.props.archive_healthInsuranceCardFront)
        console.log("archiveuserdetail this.props.archive_healthInsuranceCardNumber", this.props.archive_healthInsuranceCardNumber)

        return (
            <div className={classes.container_view}>
                <div className={classes.container_content}>
                    <div className={classes.main_container}>
                        {this.props.sessionID && <div className={classes.main_content_container}>
                            {/* <div className={classes.header_container}>
                                <div className={classes.header_view}></div>
                                <span>
                                    <div width="20px" height="20px" fill="#5A6976">
                                        <svg fill="#5A6976" width="20px" height="20px" viewBox="0 0 16 16">
                                            <g>
                                                <path fill="inherit" d="M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </span>
                                <div className={classes.header_view_text} onClick={() => this.editChatDetail()}>Edit Chat Details</div>
                            </div> */}
                            <div className={classes.header_view_text} onClick={() => this.editChatDetail()}>Edit Notes
                            </div>
                            {/*<div className={classes.generalInformation}>
                                <Fragment>
                                    <div className={classes.openchatlist} onClick={() => this.showHealthInfo()}>
                                        <div className={classes.openchatlistSection}>
                                            <div
                                                className={this.state.show_health_info === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                    <path fill="inherit" fillRule="evenodd"
                                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                </svg>
                                            </div>
                                            <div className={classes.subListTitle}>Digital Vitals</div>
                                        </div>
                                    </div>
                                    {this.state.show_health_info && healthInfoFound &&
                                    <div className={classes.expandable_section}>
                                        <div className={classes.general_info_section}>
                                            {this.props.currentSleep !== "NA" && Boolean(this.props.currentSleep) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Current Sleep:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {String(this.props.currentSleep).replace(/,/g, ' hrs ') === '' ? '-' : String(this.props.currentSleep).replace(/,/g, ' hrs ') + ' mins'}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.averageSleep !== "NA" && Boolean(this.props.averageSleep) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Average Sleep:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {String(this.props.averageSleep).replace(/,/g, ' hrs ') === 'undefined' ? '-' : String(this.props.averageSleep).replace(/,/g, ' hrs ') + ' mins'}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.heartRate !== "NA" && Boolean(this.props.heartRate) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Current BPM:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.heartRate === '' ? '-' : this.props.heartRate}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.restingHeartRate !== "NA" && Boolean(this.props.restingHeartRate) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Resting BPM:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.restingHeartRate === '' ? '-' : this.props.restingHeartRate}
                                                    </span>
                                            </div>
                                            }
                                            {((this.props.heightFeet !== "NA" && Boolean(this.props.heightFeet)) || (this.props.heightCm !== "NA" && Boolean(this.props.heightCm))) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Height:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {String(this.props.heightFeet) === '' ? '-' : String(this.props.heightFeet).replace(/,/g, `'`)}{`'`} / {this.props.heightCm === '' ? '-' : this.props.heightCm + ' cms'}
                                                    </span>
                                            </div>
                                            }
                                            {((this.props.weightLbs !== "NA" && Boolean(this.props.weightLbs)) || (this.props.weightKg !== "NA" && Boolean(this.props.weightKg))) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Weight:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.weightLbs === 'NA' ? 'NA' : this.props.weightLbs === '' ? '-' : parseInt(this.props.weightLbs) + ' lbs'} / {this.props.weightKg === '' ? '-' : this.props.weightKg + ' kgs'}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.periodStartDate !== "NA" && Boolean(this.props.periodStartDate) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Last Period:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.periodStartDate === '' ? '-' : this.props.periodStartDate}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.flowType !== "NA" && Boolean(this.props.flowType) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Flow Type:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.flowType === '' ? '-' : this.props.flowType}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.stepCount !== "NA" && Boolean(this.props.stepCount) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Avg Movement:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.stepCount === '' ? '-' : this.props.stepCount + ' steps'}
                                                    </span>
                                            </div>
                                            }
                                            {this.props.runningWalking !== "NA" && Boolean(this.props.runningWalking) &&
                                            <div>
                                                    <span className={classes.header_attr}>
                                                        Avg Walking Distance:
                                                    </span>
                                                <span
                                                    className={classes.header_attr_value}> {this.props.runningWalking === 'NA' ? 'NA' : this.props.runningWalking === '' ? '-' : parseFloat(this.props.runningWalking).toFixed(2) + ' kms'}
                                                    </span>
                                            </div>
                                            }

                                        </div>

                                    </div>
                                    }
                                </Fragment>
                                {this.state.show_health_info && !healthInfoFound &&
                                <div className={classes.list}>
                                    <div className={classes.list_text}>No Health Info Available</div>
                                </div>
                                }
                            </div>*/}

                            {<UserWarning
                                from='archives'
                            />}
                            <HealthCardSection archive_healthInsuranceCardBack={this.props.archiveOwnerProfile? this.props.archiveOwnerProfile?.healthInsuranceCardBack || null :  this.props.archive_healthInsuranceCardBack}
                                               archive_healthInsuranceCardFront={this.props.archiveOwnerProfile? this.props.archiveOwnerProfile?.healthInsuranceCardFront ||  null : this.props.archive_healthInsuranceCardFront}
                                               archive_healthInsuranceCardNumber={this.props.archiveOwnerProfile? this.props.archiveOwnerProfile?.healthInsuranceCardNumber ||  null : this.props.archive_healthInsuranceCardNumber}
                                               from={"archives"}
                            />
                            <div className={classes.generalInformation}>
                                <Fragment>
                                    <div className={classes.openchatlist} onClick={() => this.showGeneralInfo()}>
                                        <div className={classes.openchatlistSection}>
                                            <div
                                                className={this.state.show_general_info === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                    <path fill="inherit" fillRule="evenodd"
                                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                </svg>
                                            </div>
                                            <div className={classes.subListTitle}>General Information</div>
                                        </div>
                                    </div>
                                    {this.state.show_general_info &&
                                    <div className={classes.expandable_section}>
                                        <div className={classes.general_info_section}>
                                            <div><span className={classes.header_attr}>Phone #:</span> <span
                                                className={classes.header_attr_value}>{this.props.archive_phone_number || 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Address:</span> <span
                                                className={classes.header_attr_value}>{address}</span></div>
                                            <div><span className={classes.header_attr}># of Prior Encounters:</span>
                                                <span
                                                    className={classes.header_attr_value}>{this.props.chatCount}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Device:</span> <span
                                                className={classes.header_attr_value}>{this.props.platform ? this.props.platform.toUpperCase() : 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Build:</span> <span
                                                className={classes.header_attr_value}>{this.props.buildNumber ? this.props.buildNumber : 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Current Medications:</span> <span
                                                className={classes.header_attr_value}>{this.props.medication ? this.props.medication : 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Chat Session ID:</span> <span
                                                className={classes.header_attr_value}>{this.props.sessionID}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Organization ID:</span> <span
                                                className={classes.header_attr_value}>YOURDOCTORS</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Solution ID:</span> <span
                                                className={classes.header_attr_value}>{this.state.chatType === "videoCall" ? "Agora" : this.state.chatType === "audioCall" ? "Agora" : "YOURDOCTORS"}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Physician:</span> <span
                                                className={classes.header_attr_value}>True</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Physician Postal Code:</span> <span
                                                className={classes.header_attr_value}>L4W0C2</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Modality:</span> <span
                                                className={classes.header_attr_value}>{this.state.chatType === "videoCall"? "Video": this.state.chatType === "audioCall"? "Audio Only": "SecureMessage"}</span>
                                            </div>
                                        </div>

                                    </div>
                                    }
                                </Fragment>
                            </div>

                            <div className={classes.generalInformation}>
                                <Fragment>
                                    <div className={classes.openchatlist} onClick={() => this.showSOAP()}>
                                        <div className={classes.openchatlistSection}>
                                            <div
                                                className={this.state.show_soap_info === true ? classes.subListTitleIcon : classes.subListTitleIconRotate}>
                                                <svg fill="#4384F5" width="12px" height="8px" viewBox="0 0 12 8">
                                                    <path fill="inherit" fillRule="evenodd"
                                                          d="M6 7.667l-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4z"></path>
                                                </svg>
                                            </div>
                                            <div className={classes.subListTitle}>SOAP Notes</div>
                                        </div>
                                    </div>
                                    {this.state.show_soap_info &&
                                    <div className={classes.expandable_section}>
                                        <div className={classes.general_info_section}>
                                            <div><span className={classes.header_attr}>Subjective Symptoms:</span> <span
                                                className={classes.header_attr_value}>{!!this.props.symptoms ? this.props.symptoms : 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Objective Findings:</span> <span
                                                className={classes.header_attr_value}>{!!this.props.archive_findings ? this.props.archive_findings : 'N/A'}</span>
                                            </div>
                                            <div><span className={classes.header_attr}>Assessment:</span> <span
                                                className={classes.header_attr_value}>{!!this.props.archive_assessment ? this.props.archive_assessment : "N/A"}</span>
                                            </div>
                                            <div><span
                                                className={classes.header_attr}>Plan:</span>
                                                <span
                                                    className={classes.header_attr_value}>{!!this.props.archive_plan ? this.props.archive_plan : "N/A"}</span>
                                            </div>
                                            {/* <div><span className={classes.header_attr}>Patient Follow up:</span> <span
                                                className={classes.header_attr_value}>{scheduled_date !== null && scheduled_date !== undefined ? findDate(scheduled_date) : 'N/A'}</span>
                                            </div> */}
                                        </div>

                                    </div>
                                    }
                                </Fragment>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
